import React from 'react';
import {RoutePermittedPath} from 'shared/constants/AppEnums';

const Busqueda = React.lazy(() => import('./Contrato/BusquedaContratoTable'));
const Contrato = React.lazy(() => import('./Contrato/ContratoContent'));
const PasoAVenta = React.lazy(() => import('./PasoAVenta'));
const PagosPorDeposito = React.lazy(() => import('./PagosPorDeposito'));
import columns from '../Contratos/Contrato/BusquedaContratoTable/columns';
export const contratosPagesConfigs = [
  {
    permittedPath: RoutePermittedPath.contratos,
    path: ['/contratos/*'],
    element: <Busqueda columns={columns} />,
  },
  {
    permittedPath: RoutePermittedPath.pagos_por_deposito,
    path: ['/pagos-por-deposito'],
    element: <PagosPorDeposito />,
  },
  {
    permittedPath: RoutePermittedPath.contratos,
    path: ['/contrato/:id'],
    element: <Contrato />,
  },
  {
    permittedPath: RoutePermittedPath.paso_a_venta,
    path: ['/contratos-paso-a-venta'],
    element: <PasoAVenta />,
  },
];