import {
  setAbonos,
  setContrato,
  setCuentaPorCobrar,
  startLoadingContrato,
} from 'redux/store/slices/Contrato/ContratoSlice';
import jwtAxios from '@APP/services/auth/jwt-auth/jwt-api';

export const getContrato = (codigo) => {
  return async (dispatch) => {
    dispatch(startLoadingContrato());
    // AXIOS:
    const {data} = await jwtAxios.get(`contratos/${codigo}`);
    dispatch(setContrato({contrato: data}));

    // ABONOS CONTRATO
    const abonos = await jwtAxios.get('abonos/contrato/' + data.id_contrato);
    dispatch(setAbonos({abonos: abonos.data}));

    // CUENTA POR COBRAR
    const cuenta = await jwtAxios.get(
      'cuentas-por-cobrar/contrato/' + data.id_contrato,
    );

    dispatch(setCuentaPorCobrar({cuentaPorCobrar: cuenta.data}));
  };
};