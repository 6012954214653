export const getUserFromJwtAuth = (user) => {
  if (user)
    return {
      id: user.id,
      user_nickname: user.user_nickname,
      paginas: [...user.paginas],
      roles: [...user.roles],
      componentes: [...user.componentes],
    };
  return user;
};