import React from 'react';
import {Layout} from 'antd';
import './AppFooter.style.less';
import {useLayoutContext} from '../../../utility/AppContextProvider/LayoutContextProvider';

const {Footer} = Layout;

const AppFooter = () => {
  const {footer} = useLayoutContext();

  if (footer) {
    return (
      <Footer className='app-main-footer' style={{marginTop: '10px'}}>
        <p>Powered by: Ethicode &#174; {new Date().getFullYear()}</p>
        <div className='footer-btn-view'>
          <p>V1.1</p>{' '}
          <span>
            {process.env.REACT_APP_API_URL.indexOf('localhost') < 0
              ? ''
              : 'DESAROLLO'}
          </span>
        </div>
      </Footer>
    );
  }
  return null;
};

export default AppFooter;